import * as React from "react"
import Helmet from "react-helmet";
import Layout from '../../components/Layout'
import Box from "@mui/material/Box";
import {styled, useTheme} from '@mui/material/styles'
import ProjectHeader, {MyVideo} from "../../components/Common/ProjectHeader";
import {
  browserTitle,
  seoDescription,
  seoTitle,
  purposeData,
  guaranteeData,
  forecastData,
  keywords
} from "../../data/products/lng";
import Grid from "@mui/material/Grid/Grid";
import Stack from "@mui/material/Stack";
import {BackBox, Box4Title, ProductContact, SubTitleEnBox, SubTitleWhiteBox} from "../../components/Common/Base";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider/Divider";
import Typography from "@mui/material/Typography";
import {autoPlay} from "react-swipeable-views-utils";
import SwipeableViews from "react-swipeable-views";
import MobileStepper from "@mui/material/MobileStepper";

const MyImg = styled('img')(() => ({
  verticalAlign: 'bottom'
}));

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const City3d = (props) => {
  const { location } = props;

  const theme = useTheme();

  const [activeStep, setActiveStep] = React.useState(0);

  const maxSteps = 3;

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const eleShow = React.useRef();

  const handleNextClick = () => {
    eleShow.current && eleShow.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  };

  return (
    <Layout location={location}>
      <Helmet>
        <meta name="title" content={seoTitle} />
        <meta name="description" content={seoDescription} />
        <meta name="keywords" content={keywords} />
        <title>{browserTitle}</title>
      </Helmet>
      <ProjectHeader
        // backVideo={require('../../images/products/lng/01.mp4').default}
        backImg={require('../../images/products/lng/01.jpg').default}
        backVideo={'products/lng/01.m3u8'}
        subTitle={'LNG服务'}
        title={'中长期全球气温变化预测'}
        titleEn={'LNG Services'}
        titleEn2={'Short- to Long-term Global Temperature Change Projections'}
        gridShow
        handleNextClick={handleNextClick}
      />
      {/* box1 */}
      <Container
        ref={eleShow}
        maxWidth="xl"
        sx={{
          pt: {
            xs: 6,
            sm: 12,
            md: 18,
            lg: 24
          },
          pb: {
            xs: 6,
            sm: 12,
            md: 18,
            lg: 24
          }
        }}
      >
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
        >
          <Grid
            item
            xs={12}
            md={4}
          >
            <Stack
              direction={{
                xs: 'column',
                lg: 'row'
              }}
              spacing={0}
            >
              <Box4Title
                variant="h4"
                gutterBottom
                sx={{
                  textAlign: {
                    xs: 'center',
                    md: 'left'
                  },
                  whiteSpace: 'nowrap'
                }}
              >
                项目背景
              </Box4Title>
              <Box4Title
                variant="h4"
                gutterBottom
                sx={{
                  textAlign: {
                    xs: 'center',
                    md: 'left'
                  },
                  whiteSpace: 'nowrap'
                }}
              >
                Project Background
              </Box4Title>
            </Stack>
            <SubTitleWhiteBox
              variant="h5"
              gutterBottom
              sx={{
                textAlign: {
                  md: 'left'
                }
              }}
            >
              气候风险已经是我们这个时代的决定性挑战，而评估实际的气候与通过模型预测气候风险的影响是更好地了解其现在和未来的影响的重要一步。
            </SubTitleWhiteBox>
            <SubTitleEnBox gutterBottom variant="h6">
              Climate risk is already a decisive challenge of our time.
              Evaluating the actual climate and predicting the impact of
              climate risk through models is an important step to better
              understand its current and future impacts.
            </SubTitleEnBox>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
          >
            <SubTitleWhiteBox
              variant="h5"
              gutterBottom
              sx={{
                textAlign: {
                  md: 'left'
                },
                fontWeight: '100',
              }}
            >
              利用全球模型气候的输出，使用一个可细分的、透明的、可升级的、可验证结果的方法，
              通过气候风险指数来确定一个投资组合在今天之后的气候变化风险。
              我们将持续完善的气候风险评分框架，将多要素的气候模型与短时临近的天气模型结合，
              以创建一个针对包含国际天然气供需、安全、运输的高分辨率气候风险评估指数为目标，
              通过我们的评估指数和定制服务为未来的决策过程和决策流程提供长期的服务、优化和升级。
            </SubTitleWhiteBox>
            <SubTitleEnBox gutterBottom variant="h6">
              Using the output of the global model climate, use a segmentable,
              transparent, scalable, and methodology to validate results through
              a climate risk index to determine a portfolio’s post-today
              climate change risk. We will continue to refine the climate
              risk scoring framework by combining multifactor climate models
              with short-term proximity weather models to create a high-resolution
              climate risk scoring framework that encompasses international gas supply,
              demand, security, and transportation. The goal is to create a
              high-resolution climate risk assessment index that encompasses
              international gas supply and demand, security, and transportation,
              with our assessment index and customized services to serve, optimize,
              and upgrade future decision-making processes and procedures over time.
              Our assessment indexes and customized services provide long-term service,
              optimization and upgrades to future decision-making processes and processes.
            </SubTitleEnBox>
          </Grid>
        </Grid>
      </Container>
      {/* box2 */}
      <Container
        maxWidth="xl"
        sx={{
          pt: {
            xs: 4,
            md: 8,
            lg: 16,
          },
          pb: {
            xs: 4,
            md: 8,
            lg: 16,
          },
        }}
      >
        <Stack
          direction={{
            xs: 'column',
          }}
          spacing={0}
        >
          <Box4Title
            variant="h4"
            gutterBottom
            sx={{
              textAlign: {
                xs: 'center',
              }
            }}
          >
            项目概况
          </Box4Title>
          <Box4Title
            variant="h4"
            gutterBottom
            sx={{
              textAlign: {
                xs: 'center',
              }
            }}
          >
            Service Overview
          </Box4Title>
        </Stack>
        <Grid
          container
          spacing={0}
          alignItems="stretch"
        >
          <Grid
            item
            xs={12}
            md={7}
            sx={{
              backgroundSize: 'cover',
              backgroundImage: `url(${require('../../images/products/lng/021.jpg').default})`,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              p: {
                xs: 2,
                sm: 4,
                md: 6,
                lg: 8
              },
              minHeight: 300,
            }}
          >
            <SubTitleWhiteBox
              variant="h5"
              gutterBottom
              sx={{
                fontSize: {
                  sm: '2.5vw',
                  xs: '18px',
                  lg: '48px'
                },
              }}
            >
              我们力求为液化天然气行业提供定制的气象服务
            </SubTitleWhiteBox>
            <SubTitleEnBox
              gutterBottom
              variant="h6"
              sx={{
                fontSize: {
                  xs: '2vw',
                  lg: '32px'
                },
                color: '#fff',
              }}
            >
              We strive to provide customized weather
              services for the LNG industry
            </SubTitleEnBox>
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
          >
            <MyImg
              alt=""
              src={require('../../images/products/lng/022.jpg').default}
              width="100%"
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            sx={{
              backgroundColor: '#0D3140',
              p: {
                xs: 2,
                sm: 4,
                md: 6,
                lg: 8
              },
            }}
          >
            <GreenList
              listIndex={1}
              title="短期预报 Short-term forecast"
              subTitle="输气管道积雪、线路结冰和闪电提供预报"
              subTitleEn="Gas pipeline snow, line icing and lightning provide forecasts"
            />
            <GreenList
              listIndex={2}
              title="长期预报 Long-term forecasts"
              subTitle="将融合业界最先进的模型，从全球多种模型来源获得集合和确定的预测结果，
              确保专业性、前瞻性、与行业的融合度，保证模式的持续调优。利用量化数据,获得竞争优势。"
              subTitleEn="We will integrate the most advanced models in the industry,
               and obtain aggregated and definitive forecasts from multiple global model
                sources to ensure professionalism, foresight, and integration with the industry
                 to ensure continuous model tuning. Leverage quantitative data to gain a competitive advantage."
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              background: '#1BAFBF',
              p: {
                xs: 2,
                sm: 4,
                md: 6,
                lg: 8
              }
            }}
          >
            <SubTitleWhiteBox
              variant="h5"
              gutterBottom
            >
              通过实际的气候与通过模型预测气候、定制化的系统服务及一对一气候专家服务提供多角度的预测和监测服务。
              我们将提供准确的全球当地预测，在有条件的情况下，利用现场观测进行现场具体预测，以验证和提高预测的准确性。
            </SubTitleWhiteBox>
            <SubTitleEnBox
              gutterBottom
              variant="h6"
              sx={{
                color: '#fff',
              }}
            >
              We provide multi-faceted forecasting and monitoring services through actual climate versus
              predicted climate through models, customized system services, and one-on-one climate expert
              services. We will provide accurate global local forecasts and, where available,
              site-specific forecasts using in situ observations to verify and improve the accuracy of the forecasts.
            </SubTitleEnBox>
          </Grid>
        </Grid>
      </Container>
      {/* box3 */}
      <Container
        maxWidth="xl"
        sx={{
          pt: {
            xs: 4,
            md: 8,
            lg: 16,
          },
          pb: {
            xs: 4,
            md: 8,
            lg: 16,
          },
        }}
      >
        <Stack
          direction={{
            xs: 'column',
            mb: {
              xs: 2,
              md: 4
            }
          }}
          spacing={0}
        >
          <Box4Title
            variant="h4"
            gutterBottom
            sx={{
              textAlign: {
                xs: 'center',
              }
            }}
          >
            项目目的
          </Box4Title>
          <Box4Title
            variant="h4"
            gutterBottom
            sx={{
              textAlign: {
                xs: 'center',
              }
            }}
          >
            Service Purpose
          </Box4Title>
        </Stack>
        <Grid
          container
          spacing={2}
          alignItems={'stretch'}
          sx={{
            mb: 1,
            display: {
              xs: 'none',
              md: 'flex',
            }
          }}
        >
          {
            purposeData.map((item, index) => {
              return (
                <Grid
                  item
                  xs={4}
                  key={'pur-item-' + index}
                >
                  <PurposeItem
                    {...item}
                  />
                </Grid>
              )
            })
          }
        </Grid>
        <Box sx={{
          display: {
            xs: 'block',
            md: 'none',
          },
          position: 'relative',
        }}>
          <AutoPlaySwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {
              purposeData.map((item, index) => (
                <div key={'img-' + index}>
                  {
                    Math.abs(activeStep - index) <= 2 ? (
                      <Box
                        sx={{
                          height: '400px',
                          display: 'block',
                          overflow: 'hidden',
                          width: '100%',
                        }}
                      >
                        <PurposeItem
                          {...item}
                        />
                      </Box>
                    ) : null
                  }
                </div>
              ))
            }
          </AutoPlaySwipeableViews>
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <div />
            }
            backButton={
              <div />
            }
          />
        </Box>
      </Container>
      {/* box4 */}
      <Container
        maxWidth="xl"
        sx={{
          pt: {
            xs: 4,
            md: 8,
            lg: 16,
          },
        }}
      >
        <Stack
          direction={{
            xs: 'column',
            mb: {
              xs: 2,
              md: 4
            }
          }}
          spacing={0}
        >
          <Box4Title
            variant="h4"
            gutterBottom
            sx={{
              textAlign: {
                xs: 'center',
              }
            }}
          >
            特色服务
          </Box4Title>
          <Box4Title
            variant="h4"
            gutterBottom
            sx={{
              textAlign: {
                xs: 'center',
              }
            }}
          >
            Service Features
          </Box4Title>
        </Stack>
        <Grid
          container
          spacing={0}
          alignItems="stretch"
        >
          <Grid
            item
            xs={12}
            md={7}
          >
            <MyImg
              alt=""
              width="100%"
              height="100%"
              src={require('../../images/products/lng/091.jpg').default}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              backgroundColor: '#1BAFBF',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              p: {
                xs: 2,
                sm: 4,
                md: 6,
                lg: 8
              },
            }}
          >
            <SubTitleWhiteBox
              variant="h5"
              gutterBottom
            >
              LNG交易需要在全球范围内进行天气分析。<br/>
              我们提供欧洲、美洲、澳洲、亚洲、北极包括近海等地区提供详细的基于图表的未来十个月的气候信息并提供温度异常预报，
              将预测结果与历史气候的各类天气要素值进行比较，更好的判断预测值与历史往期的对比。
            </SubTitleWhiteBox>
            <br/>
            <SubTitleEnBox
              gutterBottom
              variant="h6"
              sx={{
                color: '#fff',
              }}
            >
              LNG trading requires weather analysis on a global scale.<br/>
              We provide detailed chart-based climate information for the next
              ten months for Europe, the Americas, Australia, Asia, the Arctic
              including offshore areas and provide temperature anomaly forecasts,
              comparing the forecast results with historical climate values for
              various weather elements to better judge how the forecast values
              compare with historical past periods.
            </SubTitleEnBox>
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              backgroundColor: '#1BAFBF',
              display: {
                md: 'flex',
                xs: 'none',
              },
              flexDirection: 'column',
              justifyContent: 'center',
              p: {
                xs: 2,
                sm: 4,
                md: 6,
                lg: 8
              },
            }}
          >
            <SubTitleWhiteBox
              variant="h5"
              gutterBottom
            >
              我们提供全面的天气要素包括温度、压力、湿度、风速和风向等几十种不同的数据类别。
              并根据能源消耗的区域加权的气象汇总指数。
              将全球各类天气灾害进行推送,确保一手信息可以被最快的用于局势分析，实况数据为运输路途中提供服务。
            </SubTitleWhiteBox>
            <br/>
            <SubTitleEnBox
              gutterBottom
              variant="h6"
              sx={{
                color: '#fff',
              }}
            >
              We offer a comprehensive range of weather elements including temperature,
              pressure, humidity, wind speed and wind direction in dozens of different
              data categories. We also provide a weather summary index weighted by region
              of energy consumption. All types of weather hazards around the world are
              pushed to ensure that first-hand information can be used to analyze the
              situation as quickly as possible, and that live data is available for
              transportation on the road.
            </SubTitleEnBox>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
          >
            <MyImg
              alt=""
              width="100%"
              height="100%"
              src={require('../../images/products/lng/092.jpg').default}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={5}
            sx={{
              backgroundColor: '#1BAFBF',
              display: {
                md: 'none',
                xs: 'flex',
              },
              flexDirection: 'column',
              justifyContent: 'center',
              p: {
                xs: 2,
                sm: 4,
                md: 6,
                lg: 8
              },
            }}
          >
            <SubTitleWhiteBox
              variant="h5"
              gutterBottom
            >
              我们提供全面的天气要素包括温度、压力、湿度、风速和风向等几十种不同的数据类别。
              并根据能源消耗的区域加权的气象汇总指数。
              将全球各类天气灾害进行推送,确保一手信息可以被最快的用于局势分析，实况数据为运输路途中提供服务。
            </SubTitleWhiteBox>
            <br/>
            <SubTitleEnBox
              gutterBottom
              variant="h6"
              sx={{
                color: '#fff',
              }}
            >
              We offer a comprehensive range of weather elements including temperature,
              pressure, humidity, wind speed and wind direction in dozens of different
              data categories. We also provide a weather summary index weighted by region
              of energy consumption. All types of weather hazards around the world are
              pushed to ensure that first-hand information can be used to analyze the
              situation as quickly as possible, and that live data is available for
              transportation on the road.
            </SubTitleEnBox>
          </Grid>
        </Grid>
      </Container>
      {/* box5 */}
      <Container
        maxWidth="xl"
        sx={{
          pt: {
            xs: 4,
            md: 8,
            lg: 16,
          },
          pb: {
            xs: 4,
            md: 8,
            lg: 16,
          },
        }}
      >
        <Grid
          container
          spacing={2}
          alignItems="stretch"
        >
          <Grid
            item
            xs={12}
            md={6}
          >
            <SubTitleWhiteBox
              variant="h6"
              gutterBottom
              sx={{
                fontSize: {
                  xs: '28px',
                  sm: '3.2vw',
                  lg: '35px'
                }
              }}
            >
              准确性、及时性智能分析与数据统计
            </SubTitleWhiteBox>
            <SubTitleEnBox
              variant="subtitle1"
              gutterBottom
            >
              Accuracy and Timeliness Intelligent Analysis and Data Statistics
            </SubTitleEnBox>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
          >
            <SubTitleWhiteBox
              variant="h5"
              gutterBottom
            >
              我们认为准确性和及时性至关重要，利用最先进的智能分析，
              结合高分辨率的气象模型数据和统计数据，为满足风能、水能和太阳能的要求提供服务。
              多源数据的融合将诞生新型的数据模型,我们可以根据公开数据结合行业数据,如按人口和发电量的加权指数,
              为能源消耗的需求量提供深度学习的模型,为区域和国家的电力和天然气需求进行预测,为所有模型提供大量的综合预测数据。
            </SubTitleWhiteBox>
            <br/>
            <SubTitleEnBox
              gutterBottom
              variant="h6"
            >
              We believe accuracy and timeliness are critical,
              using state-of-the-art intelligent analysis combined with high-resolution meteorological
              model data and statistical data to provide services to meet wind, hydro and solar energy
              requirements. The fusion of data from multiple sources will give birth to new types of
              data models, and we can provide deep learning models for energy consumption demand based
              on publicly available data combined with industry data, such as weighted indices by
              population and electricity generation, for regional and national electricity and gas
              demand forecasting, providing a large amount of integrated forecasting data for all models.
            </SubTitleEnBox>
          </Grid>
        </Grid>
        <Box
          sx={{
            pt: 2
          }}
        >
          <MyImg alt="" src={require('../../images/products/lng/10.jpg').default} width="100%" />
        </Box>
      </Container>
      {/* box6 */}
      <Container
        maxWidth="xl"
        sx={{
          pt: {
            xs: 4,
            md: 8,
            lg: 16,
          },
          pb: {
            xs: 4,
            md: 8,
            lg: 16,
          },
        }}
      >
        <Stack
          direction={{
            xs: 'column',
            mb: {
              xs: 2,
              md: 4
            }
          }}
          spacing={0}
        >
          <Box4Title
            variant="h4"
            gutterBottom
            sx={{
              textAlign: {
                xs: 'center',
              }
            }}
          >
            服务保障
          </Box4Title>
          <Box4Title
            variant="h4"
            gutterBottom
            sx={{
              textAlign: {
                xs: 'center',
              }
            }}
          >
            Service Guarantee
          </Box4Title>
        </Stack>
        <Grid
          container
          spacing={2}
          alignItems={'stretch'}
          sx={{
            mb: 1,
            display: {
              xs: 'none',
              md: 'flex',
            }
          }}
        >
          {
            guaranteeData.map((item, index) => {
              return (
                <Grid
                  item
                  xs={4}
                  key={'pur-item-' + index}
                >
                  <GuaranteeItem
                    {...item}
                  />
                </Grid>
              )
            })
          }
        </Grid>
        <Box sx={{
          display: {
            xs: 'block',
            md: 'none',
          },
          position: 'relative',
        }}>
          <AutoPlaySwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {
              guaranteeData.map((item, index) => (
                <div key={'img-' + index}>
                  {
                    Math.abs(activeStep - index) <= 2 ? (
                      <Box
                        sx={{
                          height: '400px',
                          display: 'block',
                          overflow: 'hidden',
                          width: '100%',
                        }}
                      >
                        <GuaranteeItem
                          {...item}
                        />
                      </Box>
                    ) : null
                  }
                </div>
              ))
            }
          </AutoPlaySwipeableViews>
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <div />
            }
            backButton={
              <div />
            }
          />
        </Box>
      </Container>
      {/* box7 */}
      <Container
        maxWidth="xl"
        sx={{
          pt: {
            xs: 4,
            md: 8,
            lg: 16,
          },
          pb: {
            xs: 4,
            md: 8,
            lg: 16,
          },
        }}
      >
        <Stack
          direction={{
            xs: 'column',
            mb: {
              xs: 2,
              md: 4
            }
          }}
          spacing={0}
        >
          <Box4Title
            variant="h4"
            gutterBottom
            sx={{
              textAlign: {
                xs: 'center',
              }
            }}
          >
            天气服务&气象数据分类
          </Box4Title>
          <Box4Title
            variant="h4"
            gutterBottom
            sx={{
              textAlign: {
                xs: 'center',
              }
            }}
          >
            Weather Service & Meteorological data classification
          </Box4Title>
        </Stack>
        <Grid
          container
          spacing={2}
          alignItems="stretch"
        >
          <Grid
            item
            xs={12}
            md={6}
          >
            <Typography
              variant="h6"
              gutterBottom
            >
              天气数据来源
            </Typography>
            <Typography
              variant="subtitle1"
              gutterBottom
            >
              Data Sources
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
          >
            <SubTitleWhiteBox
              variant="h5"
              gutterBottom
            >
              天气资料是为天气分析和预报服务的一种实时性很强的气象资料。为了取得宝贵的气象资料,
              全世界各国都建立了各类气象观测站,如地面站、探空站、测风站、火箭站、辐射站、
              农气站和自动气象站等。气象数据资料中，地面气象资料、海洋气象资料、高空气象资料、
              卫星探测资料、天气雷达探测资料和数值预报模式产品占数据总量的90%以上。
            </SubTitleWhiteBox>
            <br/>
            <SubTitleEnBox
              gutterBottom
              variant="h6"
            >
              Weather data is a kind of real-time meteorological information for weather analysis
              and forecasting. In order to obtain valuable meteorological data,
              all countries around the world have established various types of meteorological
              observation stations, such as ground stations, sounding stations,
              wind measurement stations, rocket stations, radiation stations,
              agricultural gas stations and automatic weather stations.
              Among the meteorological data, ground-based meteorological data,
              marine meteorological data, high-altitude meteorological data, satellite sounding data,
              weather radar sounding data and numerical forecast model products account for more than 90% of the total data.
            </SubTitleEnBox>
          </Grid>
        </Grid>
      </Container>
      {/* box8 */}
      <Container
        maxWidth="xl"
        sx={{
          pb: {
            xs: 4,
            md: 8,
            lg: 16,
          },
        }}
      >
        <Box sx={{
          width: {
            md: '50%',
          },
          p: 2,
          display: {
            xs: 'block',
            md: 'none',
          },
          backgroundColor: '#0D3140',
        }}>
          <GreenList
            listIndex={1}
            title="实况数据 Live Data"
            subTitle="实况数据是气象学科发展的最基础数据，也是模式数据产生的源头。简单来说，实况数据属于“一般过去时数据”，来自不同的观测设备。"
            subTitleEn="Live data are the most fundamental data for the development of meteorological disciplines and the source of model data generation. In simple terms, real data are “general past tense data” and come from different observation devices."
          />
        </Box>
        <BackBox
          sx={{
            backgroundImage: `url(${require('../../images/products/lng/15.png').default})`,
            minHeight: `calc(25vw - 12px)`,
            maxHeight: `300px`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Box sx={{
            width: {
              md: '50%',
            },
            p: 2,
            display: {
              xs: 'none',
              md: 'block',
            }
          }}>
            <GreenList
              listIndex={1}
              title="实况数据 Live Data"
              subTitle="实况数据是气象学科发展的最基础数据，也是模式数据产生的源头。简单来说，实况数据属于“一般过去时数据”，来自不同的观测设备。"
              subTitleEn="Live data are the most fundamental data for the development of meteorological disciplines and the source of model data generation. In simple terms, real data are “general past tense data” and come from different observation devices."
            />
          </Box>
        </BackBox>
        <Box sx={{
          width: {
            md: '50%',
          },
          p: 2,
          display: {
            xs: 'block',
            md: 'none',
          },
          backgroundColor: '#0D3140',
        }}>
          <GreenList
            listIndex={2}
            title="模式数据 Model Data"
            subTitle="模式数据与实况数据相比，属于预测未来的“一般将来时数据”。模式数据是由高性能计算机根据当前天气实况数据(包括地面、高空、卫星等)通过物理方程计算得出的"
            subTitleEn="Compared with real data, model data are “general future time data” for predicting the future. Model data is calculated by a high-performance computer based on current weather data (including ground, upper air, satellite, etc.) through physical equations."
          />
        </Box>
        <BackBox
          sx={{
            backgroundImage: `url(${require('../../images/products/lng/16.png').default})`,
            backgroundPosition: 'left center',
            minHeight: `calc(25vw - 12px)`,
            maxHeight: `300px`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-end',

          }}
        >
          <Box sx={{
            width: {
              md: '50%',
            },
            p: 2,
            display: {
              xs: 'none',
              md: 'block',
            }
          }}>
            <GreenList
              listIndex={2}
              title="模式数据 Model Data"
              subTitle="模式数据与实况数据相比，属于预测未来的“一般将来时数据”。模式数据是由高性能计算机根据当前天气实况数据(包括地面、高空、卫星等)通过物理方程计算得出的"
              subTitleEn="Compared with real data, model data are “general future time data” for predicting the future. Model data is calculated by a high-performance computer based on current weather data (including ground, upper air, satellite, etc.) through physical equations."
            />
          </Box>
        </BackBox>
      </Container>
      {/* box10 */}
      <Box
        sx={{
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center bottom',
          backgroundSize: 'contain',
          backgroundImage: `url(${require('../../images/products/lng/17.png').default})`,
          minHeight: `calc(25vw - 12px)`,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: {
            xs: 4,
            md: 8,
            lg: 16,
          },
          mb: {
            xs: 4,
            md: 8,
            lg: 16,
          },
        }}
      >

        <Stack
          direction={{
            xs: 'column',
            mb: {
              xs: 2,
              md: 4
            }
          }}
          spacing={0}
        >
          <Box4Title
            variant="h4"
            gutterBottom
            sx={{
              textAlign: {
                xs: 'center',
              }
            }}
          >
            数值预报模式方法简介
          </Box4Title>
          <Box4Title
            variant="h4"
            gutterBottom
            sx={{
              textAlign: {
                xs: 'center',
              }
            }}
          >
            Introduction to Numerical Forecasting<br /> Model Methodology
          </Box4Title>
        </Stack>
        <Container
          maxWidth="xl"
          sx={{
            p: 4
          }}

        >
          <Grid
            container
            spacing={2}
            direction="row-reverse"
          >
            <Grid
              item
              xs={12}
              md={6}
            >
              <SubTitleWhiteBox
                variant="h5"
                gutterBottom
              >
                模式系统一般每天计算4次，在整点开始，利用整点前采集到的实况数据进行计算，
                每次计算要生成几百个物理量，包括从开始计算的时刻(又称作“起报时刻”)至未来240小时时效(或更长)
                的一系列二进制网格数据，预报时效间隔3小时。目前气象网格经纬度间距一般在0.25度数量级，
                一个网格文件大小通常在1～2兆，包含几十万个浮点数值。
              </SubTitleWhiteBox>
              <br/>
              <SubTitleEnBox
                gutterBottom
                variant="h6"
              >
                The model system generally calculates four times a day, starting at the full
                point and using the live data collected before the full point, generating several
                hundred physical quantities per calculation, including a series of binary grid
                data from the time of the start of the calculation (also known as the “starting point”)
                to the next 240 hours (or longer), with a forecast interval of 3 hours. hours.
                Currently, the latitude and longitude spacing of weather grids is generally in
                the order of 0.25 degrees, and the size of a grid file is usually in the range of
                1 to 2 megabytes, containing hundreds of thousands of floating point values.
              </SubTitleEnBox>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
            >
              <img alt="" align="center" width="80%" src={require('../../images/products/lng/25.png').default} />
            </Grid>
          </Grid>
        </Container>
      </Box>
      {/* box11 */}
      <Container
        maxWidth="xl"
        sx={{
          pt: {
            xs: 4,
            md: 8,
            lg: 16,
          },
          pb: {
            xs: 4,
            md: 8,
            lg: 16,
          },
        }}
      >
        <Stack
          direction={{
            xs: 'column',
            mb: {
              xs: 2,
              md: 4
            }
          }}
          spacing={0}
        >
          <Box4Title
            variant="h4"
            gutterBottom
            sx={{
              textAlign: {
                xs: 'center',
              }
            }}
          >
            预报服务
          </Box4Title>
          <Box4Title
            variant="h4"
            gutterBottom
            sx={{
              textAlign: {
                xs: 'center',
              }
            }}
          >
            Forecast Service
          </Box4Title>
        </Stack>
        <Grid
          container
          spacing={{
            xs: 10,
            md: 4
          }}
          alignItems={'stretch'}
          justifyContent="center"
          sx={{
            mb: {
              xs: 4,
              md: 6
            },
          }}
        >
          {
            forecastData.map((item, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  md={6}
                  key={'pur-item-' + index}
                >
                  <ForecastItem
                    {...item}
                  />
                </Grid>
              )
            })
          }
        </Grid>
      </Container>
      {/* box12 */}

      {/* box13 */}
      <Container
        maxWidth="xl"
        sx={{
          pt: {
            xs: 4,
            md: 8,
            lg: 16,
          },
          pb: {
            xs: 4,
            md: 8,
            lg: 16,
          },
        }}

      >
        <Stack
          direction={{
            xs: 'column',
            mb: {
              xs: 2,
              md: 4
            }
          }}
          spacing={0}
        >
          <Box4Title
            variant="h4"
            gutterBottom
            sx={{
              textAlign: {
                xs: 'center',
              }
            }}
          >
            定制化的系统服务
          </Box4Title>
          <Box4Title
            variant="h4"
            gutterBottom
            sx={{
              textAlign: {
                xs: 'center',
              }
            }}
          >
            Customized System Services
          </Box4Title>
        </Stack>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            md={6}
          >
            <MyImg alt="" width="100%" src={require('../../images/products/lng/23.png').default} />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
          >
            <SubTitleWhiteBox
              variant="h5"
              sx={{
                pt: 4
              }}
            >
              全球及中国大陆地区中短期气温变化预测系统
            </SubTitleWhiteBox>
            <br/>
            <SubTitleEnBox
              gutterBottom
              variant="h6"
              sx={{
                mb: 2
              }}
            >
              Short- to medium-term temperature change forecasting system for global and mainland China
            </SubTitleEnBox>
            <MyImg alt="" width="60%" src={require('../../images/products/lng/24.png').default} />
          </Grid>
        </Grid>
      </Container>
      {/* box14 */}
      <ProductContact />
    </Layout>
  )
}


export default City3d;

const ListAvatar = styled(Box)(({theme}) => ({
  width: 32,
  height: 32,
  textAlign: 'center',
  color: '#0D3140',
  fontSize: '20px',
  backgroundColor: '#04D94F',
  lineHeight: '32px',
  borderRadius: '50%',
  marginRight: 16,
  fontWeight: 'bold',
  [theme.breakpoints.up('sm')]: {
    height: '5vw',
    width: '5vw',
    lineHeight: '5vw',
    fontSize: '2.8vw',
  },
  [theme.breakpoints.up('md')]: {
    height: 40,
    width: 40,
    lineHeight: '40px',
    fontSize: '28px',
  },

}));

const GreenList = (props) => {
  const { listIndex, title, subTitle, subTitleEn } = props;
  return (
    <Box
      sx={{
        pb: 2
      }}
    >
      <Stack
        alignItems='center'
        flexDirection="row"
      >
        <ListAvatar>{listIndex}</ListAvatar>
        <Box
          sx={{
            color: '#04D94F',
            fontSize: {
              xs: '16px',
              sm: '2.5vw',
              md: '24px',
            }
          }}
        >
          {
            title
          }
        </Box>
      </Stack>
      <Box
        sx={{
          pl: {
            xs: 6,
            sm: 'calc(5vw + 16px)',
            md: '56px'
          }
        }}
      >
        <SubTitleWhiteBox
          variant="h5"
          gutterBottom
        >
          {subTitle}
        </SubTitleWhiteBox>
        <SubTitleEnBox
          gutterBottom
          variant="h6"
          sx={{
            // color: '#fff',
          }}
        >
          {subTitleEn}
        </SubTitleEnBox>
      </Box>
    </Box>
  )
};

const ImgCenter = styled(Box)(({theme}) => ({
  width: 50,
  height: 50,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  [theme.breakpoints.up('md')]: {
    width: 60,
    height: 60,
  }
}));

const PurposeItem = (props) => {
  const { imagePath, title, titleEn, subTitle, subTitleEn } = props;
  return (
    <Stack
      sx={{
        height: '100%',
        p: 2,
        backgroundColor: '#071D26',
        borderRadius: '6px',
        maxWidth: '400px',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
      alignItems="center"
    >
      <ImgCenter
        sx={{
          m: {
            xs: 2,
            md: 4,
          },
          backgroundImage: `url(${imagePath})`
        }}
      />
      <Box
        sx={{
          height: {
            md: '200px',
            xs: '100px'
          }
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          align="center"
          sx={{
            fontSize: {
              xs: '16px',
              sm: '18px',
              md: '20px'
            },
            color: '#1BAFBF',
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontSize: {
              xs: '14px',
              sm: '16px',
              md: '18px'
            },
            color: '#1BAFBF',
          }}
          align="center"
        >
          {titleEn}
        </Typography>
      </Box>
      <Divider style={{width: '100%'}} />
      <Box
        sx={{
          flex: 1,
          pt: {
            xs: 2,
            md: 4
          },
          pb: {
            xs: 2,
            md: 4
          },
        }}
      >
        <SubTitleWhiteBox
          variant="h5"
          gutterBottom
        >
          {subTitle}
        </SubTitleWhiteBox>
        <SubTitleEnBox
          gutterBottom
          variant="h6"
          sx={{
            // color: '#fff',
          }}
        >
          {subTitleEn}
        </SubTitleEnBox>
      </Box>
    </Stack>
  )
}

const ImgCenter2 = styled(Box)(({theme}) => ({
  position: 'relative',
  marginTop: -40,
  width: 80,
  height: 80,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  [theme.breakpoints.up('md')]: {
    width: 100,
    height: 100,
    marginTop: -50,
  }
}));

const GuaranteeItem = (props) => {
  const { imagePath, title, titleEn, subTitle, subTitleEn } = props;
  return (
    <Stack
      sx={{
        height: '100%',
        p: 2,
        pt: 0,
        backgroundColor: '#071D26',
        borderRadius: '6px',
        maxWidth: '400px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '50px',
      }}
      alignItems="center"
    >
      <ImgCenter2
        sx={{
          mb: {
            xs: 2,
            md: 4,
          },
          backgroundImage: `url(${imagePath})`
        }}
      />
      <Box
        sx={{
          height: {
            md: '200px',
            xs: '100px'
          }
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          align="center"
          sx={{
            fontSize: {
              xs: '16px',
              sm: '18px',
              md: '20px'
            },
            color: '#1BAFBF',
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontSize: {
              xs: '14px',
              sm: '16px',
              md: '18px'
            },
            color: '#1BAFBF',
          }}
          align="center"
        >
          {titleEn}
        </Typography>
      </Box>
      <Divider style={{width: '100%'}} />
      <Box
        sx={{
          flex: 1,
          pt: {
            xs: 2,
            md: 4
          },
          pb: {
            xs: 2,
            md: 4
          },
        }}
      >
        <SubTitleWhiteBox
          variant="h5"
          gutterBottom
        >
          {subTitle}
        </SubTitleWhiteBox>
        <SubTitleEnBox
          gutterBottom
          variant="h6"
          sx={{
            // color: '#fff',
          }}
        >
          {subTitleEn}
        </SubTitleEnBox>
      </Box>
    </Stack>
  )
}


const ForecastItem = (props) => {
  const { imagePath, title, titleEn, subTitle, subTitleEn, subTitle2, subTitleEn2 } = props;
  return (
    <Stack
      sx={{
        height: '100%',
        p: 2,
        pt: 0,
        backgroundColor: '#071D26',
        borderRadius: '6px',
        ml: 'auto',
        mr: 'auto',
        mt: '50px',
      }}
      alignItems="center"
    >
      <ImgCenter2
        sx={{
          mb: {
            xs: 2,
            md: 4,
          },
          backgroundImage: `url(${imagePath})`
        }}
      />
      <Box
        sx={{
          height: {
            md: '100px',
            xs: '60px'
          }
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          align="center"
          sx={{
            fontSize: {
              xs: '16px',
              sm: '18px',
              md: '20px'
            },
            color: '#1BAFBF',
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontSize: {
              xs: '14px',
              sm: '16px',
              md: '18px'
            },
            color: '#1BAFBF',
          }}
          align="center"
        >
          {titleEn}
        </Typography>
      </Box>
      <Divider style={{width: '100%'}} />
      <Box
        sx={{
          flex: 1,
          pt: {
            xs: 2,
            md: 4
          },
        }}
      >
        <SubTitleWhiteBox
          variant="h5"
          gutterBottom
        >
          {subTitle}
        </SubTitleWhiteBox>
        <SubTitleEnBox
          gutterBottom
          variant="h6"
          sx={{
            color: '#fff',
          }}
        >
          {subTitleEn}
        </SubTitleEnBox>
      </Box>
      <Box
        sx={{
          pb: 2,
          width: '100%',
        }}
      >
        <SubTitleWhiteBox
          variant="h5"
          gutterBottom
          sx={{
            color: '#1BAFBF'
          }}
        >
          {subTitle2}
        </SubTitleWhiteBox>
        <SubTitleEnBox
          gutterBottom
          variant="h6"
          sx={{
            color: '#1BAFBF',
          }}
        >
          {subTitleEn2}
        </SubTitleEnBox>
      </Box>
    </Stack>
  )
}
